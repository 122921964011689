<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>Bienvenido a CréditoRápido</h1>
        <h1>Tu Solución Financiera Rápida </h1>
        <h1>y Confiable</h1>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/img_apple_store.png" alt="">
        </div>
      </div>
    </div>
    <div class="inner inner2">
      <h1>¿Por qué elegir CréditoRápido?</h1>
      <div class="content flex fw-w jc-sb">
        <div v-for="( item,index ) in inner_2item" :key="index" :style="{width:'24%'}" class="div_item">
          <!-- <img :src="item.img"> -->
          <div class="div_bule">{{ index + 1 }}</div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.txt }}</p>
          <p v-if="item.txt2">{{ item.txt2 }}</p>
          <p v-if="item.txt3">{{ item.txt3 }}</p>
        </div>
      </div>
    </div>
    <div class="inner inner3 flex ai-c jc-c">
      <div>
        <h1>Cómo Funciona</h1>
        <div class="div_flex">
          <div class="div_tag">1</div>
          <span>RRegístrate: Descarga la aplicación CréditoRápido desde la App Store o Google Play Store y crea tu cuenta en minutos.</span>
        </div>
        <div class="div_flex">
          <div class="div_tag">2</div>
          <span>Solicita tu Préstamo: Completa nuestro formulario de solicitud en línea y obtén una respuesta instantánea.</span>
        </div>
        <div class="div_flex">
          <div class="div_tag">3</div>
          <span>Recibe tu Dinero: Una vez aprobado, el dinero se depositará directamente en tu cuenta bancaria en cuestión de minutos.</span>
        </div>
        <div class="div_flex">
          <div class="div_tag">4</div>
          <span>¡Es así de simple! No esperes más, únete a miles de clientes satisfechos y experimenta la comodidad y la rapidez de CréditoRápido hoy mismo.</span>
        </div>
      </div>
      <img src="@/assets/images/loanImg/inner3_phone.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_2item: [
        { title: 'Rápido y Fácil:',
          txt: 'Olvídate de trámites complicados y largas esperas. \n Con CréditoRápido, obtén tu dinero en cuestión de minutos, sin papeleo engorroso.'
          // txt2: 'Aprobación rápida: Recibe una respuesta \n en tiempo récord.',
          // txt3: 'Dinero en tu cuenta: Disfruta de tu \n préstamo directamente en tu cuenta \n bancaria.',
          // img: require('@/assets/images/icon-4.png')
        },
        { title: 'Seguro y Confiable:',
          txt: 'Tu seguridad es nuestra prioridad. \n Nuestra plataforma utiliza las últimas tecnologías de encriptación para proteger tus datos personales en todo momento.'
          // txt2: 'Sin sorpresas: Conoce todos los costos \n antes de aceptar tu préstamo.',
          // img: require('@/assets/images/icon-5.png')
        },
        { title: 'Accesible para Todos: ',
          txt: 'No importa tu historial crediticio, en CréditoRápido creemos que todos merecen acceso a soluciones financieras. ¡Solicita tu préstamo hoy mismo!'
          // txt2: 'Preguntas frecuentes: Encuentra respuestas \n a las dudas más comunes.',
          // img: require('@/assets/images/icon-7.png')
        }
      ]
    }
  },
  methods: {
    downloadApp() {
      // window.open('https://play.google.com/store/apps/details?id=we.prestamos.rapido')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    font-family: Arial, Arial;
    background: #fff;
    .inner {
      width:1400px;
      // padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1 {

      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      // background-size: contain;
      background-size: cover;
      // background-attachment: fixed;
      background-position: center;
      height: 500px;
      display: flex;
      align-items: center;
      color: black;
      padding: 75px 95px;
      .left_item{
        float: left;
        width: 740px;
        h1{
          font-size: 27px;
          color: white;
          font-weight: 700;
          margin-bottom: 32px;
          line-height: 40px;
        }
        h2{
          padding-right: 17.5px;
          font-size: 30px;
          line-height: 26px;
        }
        .item_download{
          margin-top: 20px;
          justify-content:start;
          img{
            width: 136px;
            height: 40px;
          }
        }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      background: #F7F7F7;
      padding: 57px 95px 75px;
      text-align: center;
      h1 {
          color:#333333;
          font-size: 22px;
          line-height: 50px;
          margin-bottom: 44px;
        }
      .content{
        // padding: 0 2rem;
        .div_item {
          padding: 30px 25px;
          background: white;
          text-align: center;
          font-size: 18px;
          line-height: 24px;
          // border-radius: 15px;
          position: relative;
          h3{
            text-align: left;
            line-height: 50px;
            font-size: 14px;
          }
          p {
            text-align: left;
            margin-bottom: 10px;
            font-size: 9px;
            color: #666666;
            line-height: 13px;
          }
          img{
            width: 50px;
          }
          .div_bule {
            position: absolute;
            color: white;
            background: #4262E9;
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 10px 0 10px 10px;
            right: 0;
            top: 0;
          }
        }
      }
    }
    .inner3 {
      font-family: Arial, Arial;
      width: 100%;
      background: #1F3EC1;
      color: white;
      line-height: 24px;
      padding: 0 95px;
      .div_flex {
        display: flex;
        margin-bottom: 10px;
        .div_tag {
          font-size: 12px;
          height: 24px;
          width: 24px;
          text-align: center;
          border-radius: 24px;
          color: #1F3EC1;
          background: white;
          margin-right: 21px;
        }
      }

      h1{
        line-height: 60px;
      }
      img {
        width: 446px;

      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
      width: 100vw !important;
      height: auto;
    }
    .inner1 {
      min-height: 160px;
      position: relative;
      padding: 25px 20px;
      h1{
        font-size: 18px !important;
        line-height: 25px !important;
        margin-bottom: 0 !important;
      }
      h2{
        font-size: 12px !important;
      }
      .left_item{
        // margin: 25px 0;
        .item_download{
          margin-top: 20px;
          justify-content:start;
          img {
            width: 136px;
            height: 40px;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2{
      padding: 0;
      h1{
        font-size: 25px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
      .content{
        padding: 0;
        justify-content: center !important;
        .div_item{
          width: 90% !important;
          padding: 40px 20px 20px;
          font-size: 14px;
          margin-bottom: 20px;
          img{
            width: 60px;
            top: -30px;
            left: calc(50% - 30px);
          }
        }
      }
    }
    .inner3{
      h2 {
        text-align: center;
      }
      p {
        padding: 0 30px;
      }
      img {
        margin: 20px 0 ;
      }
    }
  }
}
</style>
